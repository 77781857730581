<div
  class="app-body"
  style="
    background-image: url('../../../assets/img/europe.jpg');
    background-size: cover;
  "
>
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4" style="border: 0px; opacity: 95%">
              <div class="card-body">
                <form [formGroup]="loginForm" (ngSubmit)="goLogin()">
                  <h1
                    style="color: white;
                  background: darkblue;
                  text-align: center;
              }"
                  >
                    Login
                  </h1>
                  <p class="text-muted">Sign In to your account</p>
                  <div class="input-group mb-3 form-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="icon-user"></i
                      ></span>
                    </div>
                    <input
                      (keydown.space)="$event.preventDefault()"
                      class="form-control"
                      autocomplete="off"
                      placeholder="email"
                      formControlName="email"
                      type="text"
                      [(ngModel)]="model.email"
                      [ngClass]="{ 'is-invalid': submitted && lf.email.errors }"
                    />
                    <div
                      *ngIf="submitted && lf.email.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="lf.email.errors.required">
                        Please enter email.
                      </div>
                      <div *ngIf="lf.email.errors.pattern">
                        Please enter valid email.
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-4 form-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="icon-lock"></i
                      ></span>
                    </div>
                    <input
                      input
                      id="password1"
                      class="form-control"
                      type="password"
                      autocomplete="off"
                      placeholder="Password"
                      formControlName="password"
                      [(ngModel)]="model.password"
                      [ngClass]="{
                        'is-invalid': submitted && lf.password.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && lf.password.errors"
                      class="invalid-feedback"
                    >
                      <div
                        *ngIf="
                          lf.password.errors.required ||
                          lf.password.errors.whiteSpace
                        "
                      >
                        Please enter password.
                      </div>
                      <div *ngIf="lf.password.errors.minlength">
                        Password length must be at least 5 characters long.
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button
                        type="submit"
                        class="btn btn-primary px-4"
                        style="background: darkblue !important"
                      >
                        Login
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <p>
                        If you cannot remember your password, you can Reset your
                        password on AU Login.
                      </p>
                      <!-- <button type="button" class="btn btn-link px-0">Forgot password?</button> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
